* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Encode Sans Expanded', sans-serif;
}

body {
  background-color: #171717;
  min-width: 380px;
}

span {
  color: #FF4532;
}

a {
  color: white;
  text-decoration: none;
  z-index: 5 !important;
}

div {
  z-index: 1;
  text-decoration: none;
}

/* .carousel .slide img {
  width: 50% !important;
} */